<template>
    <div class="Service">
        <iframe class="iframe" :src="src" frameborder="0"></iframe>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { proxy, GOPAY_KTOKEN } from '@/assets/js/common.js'

export default {
    setup() {
        const state = reactive({
            src: proxy + '/im/login.html?KToken=' + escape(localStorage.getItem(GOPAY_KTOKEN))
        })

        const router = useRouter()
        const store = useStore()

        return {
            ...toRefs(state),
            router
        }
    }
}
</script>

<style lang="scss" scoped>
.Service {
    height: 100%;

    .iframe {
        width: 100%;
        height: 100%;
    }
}
</style>
